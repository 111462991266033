import Vue from "./bootstrap";
import App from "./App.vue";
import router from "./router";
import "./quasar";
import "./assets/css/estilos.scss";
import "./assets/css/iconos.css";
import config from "./Services/Global/getConfig";
import { registerLicense } from "@syncfusion/ej2-base";

registerLicense(
  "ORg4AjUWIQA/Gnt2VVhjQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0VjX35adXFRQWRYVEU="
);

config.getApi().then(() => {
  new Vue({
    router,
    render: (h) => h(App),
  }).$mount("#app");
});
