
export default {
  props: {
    model: { type: Boolean, required: true },
    tituloModal: { type: String, required: true },
    labelAceptar: { type: String, default: "Aceptar" },
    tabIndexUltimoElemento: {
      type: String,
      default: "2",
    },
  },
  data() {
    return {
      mostrar: false,
    };
  },
  watch: {
    model(val) {
      this.mostrar = val;
    },
  },
  methods: {
    onClose() {
      this.$emit("cerrar-modal");
    },
    aceptar() {
      this.$emit("aceptar");
    },
    regresarUltimoElemento() {
      document
        .querySelector('[tabindex="' + this.tabIndexUltimoElemento + '"]')
        .focus();
    },
    regresarBtnGuardar() {
      document.querySelector('[tabindex="100"]').focus();
    },
    irPrimerElemento() {
      document.querySelector('[tabindex="2"]').focus();
    },
  },
};
