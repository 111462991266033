
import methodsSyncfusion from "../../metodosSyncfusion";
export default {
  name: "gestor-global",
  props: {
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    idGrid: {
      type: String,
      default: "idGrid",
    },
    refGrid: {
      type: String,
      default: "refGrid",
    },
    alturaCalculada: {
      type: String,
      default: "220px",
    },
    allowPaging: {
      type: Boolean,
      default: true,
    },
    allowFiltering: {
      type: Boolean,
      default: true,
    },
    allowSorting: {
      type: Boolean,
      default: true,
    },
    allowReordering: {
      type: Boolean,
      default: true,
    },
    allowResizing: {
      type: Boolean,
      default: true,
    },
    allowSelection: {
      type: Boolean,
      default: true,
    },
    showColumnChooser: {
      type: Boolean,
      default: true,
    },
    pageSettings: {
      type: Object,
      default: () => {
        return { pageSize: 100 };
      },
    },
    filterSettings: {
      type: Object,
      default: () => {
        return { type: "Excel", ignoreAccent: true };
      },
    },
    toolbar: {
      type: Array,
      default: () => {
        return ["ColumnChooser"];
      },
    },
    doClear: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    doClear() {
      if (this.doClear == true) {
        this.$refs.refGrid.clearFiltering();
        this.$refs.refGrid.clearSorting();
        this.$emit("reset-clear");
      }
    },
  },
  provide: {
    grid: methodsSyncfusion.getMethodsGridSyncfusion(),
  },
  methods: {
    manejarClickFolioRuta(e, col, data) {
      if (e.ctrlKey == true) {
        this.irRutaNuevaTab(col, data);
      } else {
        this.irRuta(col, data);
      }
    },
    irRuta(col, data) {
      this.$router.push({
        name: col.routeName,
        params: {
          id: data[col.rutaId],
          hash: this.obtenerHash(data[col.rutaId]),
        },
      });
    },
    irRutaNuevaTab(col, data) {
      const rutaNueva = this.$router.resolve({
        name: col.routeName,
        params: {
          id: data[col.rutaId],
          hash: this.obtenerHash(data[col.rutaId]),
        },
      });
      window.open(rutaNueva.href);
    },
    obtenerStatusNombre(data, col) {
      return String(data[col.statusName]);
    },
    manejarClickFolioAccion(col, data) {
      this.$emit("click-folio", data[col.rutaId]);
    },
    manejarClickOpcion(obj, accion) {
      this.$emit("click-opcion", obj, accion);
    },
    rowDataBound: function (args) {
      if (args.data["itemSeleccionado"] != undefined) {
        if (args.data["itemSeleccionado"] == true)
          args.row.classList.add("e-active");
        else args.row.classList.remove("e-active");
      }
    },
  },
};
