import ValidarPermisoService from "../Services/ValidarPermisoService";
import permisos from "../permisos";
import ValidacionHash from "@/Middlewares/ValidacionHash";

export default [
  {
    path: "/login",
    component: () => import("../views/login/Login.vue"),
    name: "login",
  },
  /***********************************************************/
  /***************** RUTAS CONFIGURACIONES *******************/
  /***********************************************************/
  {
    path: "/configuraciones/",
    component: () => import("../components/layouts/LayoutConfiguracion.vue"),
    beforeEnter: (to, from, next) => {
      ValidarPermisoService.validarAccesoRuta(
        permisos.CONFIGURACIONES_VISUALIZAR,
        next
      );
    },
    children: [
      {
        path: "home",
        component: () => import("../views/home/HomeConfiguracion.vue"),
        name: "homeConfiguraciones",
        meta: { requiresAuth: true },
      },
      {
        path: "datosGenerales",
        component: () =>
          import("../views/configuraciones/cliente/DatosGeneralesDetalle.vue"),
        name: "datosGenerales",
        meta: { requiresAuth: true },
      },
      {
        path: "usuarios",
        component: () =>
          import("../views/configuraciones/usuarios/UsuarioGestor.vue"),
        name: "usuariosGestor",
        meta: { requiresAuth: true },
      },
      {
        path: "usuarios/detalle/:id/:hash?",
        component: () =>
          import("../views/configuraciones/usuarios/UsuarioDetalle.vue"),
        name: "usuariosDetalle",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          ValidacionHash.validarHash(to.params.id, to.params.hash, next),
            next();
        },
      },
      {
        path: "articulosCategorias",
        component: () =>
          import(
            "../views/configuraciones/articulosCategorias/ArticuloCategoriaGestor.vue"
          ),
        name: "articulosCategorias",
        meta: { requiresAuth: true },
      },
      {
        path: "perfiles",
        component: () =>
          import("../views/configuraciones/perfiles/PerfilGestor.vue"),
        name: "perfilesGestor",
        meta: { requiresAuth: true },
      },
      {
        path: "perfiles/detalle/:id/:hash?",
        component: () =>
          import("../views/configuraciones/perfiles/PerfilDetalle.vue"),
        name: "perfilesDetalle",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          ValidacionHash.validarHash(to.params.id, to.params.hash, next),
            next();
        },
      },
      {
        path: "embudos",
        component: () =>
          import("../views/configuraciones/embudos/EmbudoGestor.vue"),
        name: "embudosGestor",
        meta: { requiresAuth: true },
      },
      {
        path: "articulosMarcas",
        component: () =>
          import(
            "../views/configuraciones/articulosMarcas/ArticuloMarcaGestor.vue"
          ),
        name: "articulosMarcas",
        meta: { requiresAuth: true },
      },
      {
        path: "datosSMTP",
        component: () =>
          import("../views/configuraciones/cliente/DatosSMTPDetalle.vue"),
        name: "datosSMTP",
        meta: { requiresAuth: true },
      },
      {
        path: "industrias",
        component: () =>
          import("../views/configuraciones/industrias/IndustriaGestor.vue"),
        name: "industriasGestor",
        meta: { requiresAuth: true },
      },
      {
        path: "plataformas",
        component: () =>
          import("../views/configuraciones/plataformas/PlataformaGestor.vue"),
        name: "plataformasGestor",
        meta: { requiresAuth: true },
      },
      {
        path: "campanias",
        component: () =>
          import("../views/configuraciones/campanias/CampaniaGestor.vue"),
        name: "campaniasGestor",
        meta: { requiresAuth: true },
      },
      {
        path: "canalesCaptacion",
        component: () =>
          import(
            "../views/configuraciones/canalesCaptacion/CanalCaptacionGestor.vue"
          ),
        name: "canalesCaptacionGestor",
        meta: { requiresAuth: true },
      },
    ],
  },
  /***********************************************************/
  /******************* RUTAS GENERALES ***********************/
  /***********************************************************/
  {
    path: "/",
    component: () => import("../components/layouts/LayoutGlobal.vue"),
    children: [
      {
        path: "home",
        component: () => import("../views/home/Home.vue"),
        name: "home",
      },
      // Leads
      {
        path: "leads",
        component: () => import("../views/leads/LeadGestor.vue"),
        name: "leadsGestor",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) =>
          ValidarPermisoService.validarAccesoRuta(
            permisos.CONTACTOS_VISUALIZAR,
            next
          ),
      },
      {
        path: "leads/detalle/:id/:hash?",
        component: () => import("../views/leads/LeadDetalle.vue"),
        name: "leadsDetalle",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          ValidacionHash.validarHash(to.params.id, to.params.hash, next),
            ValidarPermisoService.validarAccesoRuta(
              permisos.CONTACTOS_VISUALIZAR,
              next
            );
        },
      },
      // Productos
      {
        path: "productos",
        component: () => import("../views/productos/ProductoGestor.vue"),
        name: "productosGestor",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) =>
          ValidarPermisoService.validarAccesoRuta(
            permisos.ARTICULOS_VISUALIZAR,
            next
          ),
      },
      {
        path: "productos/detalle/:id/:hash?",
        component: () => import("../views/productos/ProductoDetalle.vue"),
        name: "productosDetalle",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          ValidacionHash.validarHash(to.params.id, to.params.hash, next),
            ValidarPermisoService.validarAccesoRuta(
              permisos.ARTICULOS_VISUALIZAR,
              next
            );
        },
      },
      // Oportunidades
      {
        path: "oportunidades",
        component: () => import("../views/oportunidades/OportunidadGestor.vue"),
        name: "oportunidadesGestor",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) =>
          ValidarPermisoService.validarAccesoRuta(
            permisos.OPORTUNIDADES_VISUALIZAR,
            next
          ),
      },
      {
        path: "oportunidades/detalle/:id/:hash?",
        component: () =>
          import("../views/oportunidades/OportunidadDetalle.vue"),
        name: "oportunidadesDetalle",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          ValidacionHash.validarHash(to.params.id, to.params.hash, next),
            ValidarPermisoService.validarAccesoRuta(
              permisos.OPORTUNIDADES_VISUALIZAR,
              next
            );
        },
      },
      // Emails
      {
        path: "emails",
        component: () => import("../views/emails/EmailGestor.vue"),
        name: "emailsGestor",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) =>
          ValidarPermisoService.validarAccesoRuta(
            permisos.EMAILS_VISUALIZAR,
            next
          ),
      },
      // Conversaciones
      {
        path: "conversaciones",
        component: () =>
          import("../views/conversaciones/ConversacionGestor.vue"),
        name: "conversacionesGestor",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) =>
          ValidarPermisoService.validarAccesoRuta(
            permisos.CONVERSACIONES_VISUALIZAR,
            next
          ),
      },
      // Seguimientos
      {
        path: "seguimientos",
        component: () => import("../views/seguimientos/SeguimientoGestor.vue"),
        name: "seguimientosGestor",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) =>
          ValidarPermisoService.validarAccesoRuta(
            permisos.SEGUIMIENTOS_VISUALIZAR,
            next
          ),
      },
      {
        path: "seguimientos/detalle/:id/:hash?",
        component: () => import("../views/seguimientos/SeguimientoDetalle.vue"),
        name: "seguimientosDetalle",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          ValidacionHash.validarHash(to.params.id, to.params.hash, next),
            ValidarPermisoService.validarAccesoRuta(
              permisos.SEGUIMIENTOS_VISUALIZAR,
              next
            );
        },
      },
      // Embudo ventas
      {
        path: "embudoVentas",
        component: () => import("../views/embudos/EmbudoVentaGestor.vue"),
        name: "embudoVentasGestor",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) =>
          ValidarPermisoService.validarAccesoRuta(
            permisos.EMBUDO_VISUALIZAR,
            next
          ),
      },
    ],
  },
];
