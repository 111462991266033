
export default {
  name: "FiltroBarraGlobal",
  props: {
    mostrarAgregar: { type: Boolean, default: false },
    mostrarAbrirFiltros: { type: Boolean, default: false },
    etiquetaAgregar: {
      type: String,
      default: "Agregar",
    },
    iconoAgregar: { type: String, default: "mas" },
  },
  data() {
    return {};
  },
  methods: {
    abrirModalAgregar() {
      this.$emit("abrir-modal-agregar");
    },
    abrirModalFiltros() {
      this.$emit("abrir-modal-filtros");
    },
    buscar() {
      this.$emit("buscar");
    },
    limpiar() {
      this.$emit("limpiar");
    },
  },
};
