
export default {
  name: "link-ir-global",
  props: {
    rutaNombre: {
      type: String,
      required: true,
    },
    rutaId: {
      type: Number,
      required: true,
    },
    texto: {
      type: [Number, String],
      required: true,
    },
    claseTexto: {
      type: String,
      default: "",
    },
    claseIcono: {
      type: String,
      default: "",
    },
  },
};
