
export default {
  name: "status-cadena-global",
  props: {
    statusNombre: {
      type: String,
    },
  },
  data() {
    return {
      color: "",
      classStyle: "",
    };
  },
  watch: {
    statusNombre() {
      this.cargarEstilos();
    },
  },
  mounted() {
    this.cargarEstilos();
  },
  methods: {
    cargarEstilos() {
      switch (this.statusNombre) {
        case this.constantesStatusCadena.lead:
        case this.constantesStatusCadena.oportunidadEnProceso:
          this.classStyle = "bg-color-en-proceso";
          break;
        case this.constantesStatusCadena.cliente:
        case this.constantesStatusCadena.oportunidadCerrada:
        case this.constantesStatusCadena.campaniaFinalizada:
        case this.constantesStatusCadena.seguimientoCompletado:
        case this.constantesStatusCadena.canalCaptacionActivo:
          this.classStyle = "bg-color-activo";
          break;
        case this.constantesStatusCadena.descartado:
        case this.constantesStatusCadena.oportunidadDescartada:
        case this.constantesStatusCadena.campaniaDescartada:
        case this.constantesStatusCadena.seguimientoCancelado:
        case this.constantesStatusCadena.canalCaptacionInactivo:
          this.classStyle = "bg-color-descartado";
          break;
        case this.constantesStatusCadena.campaniaPendiente:
          this.classStyle = "bg-color-pendiente";
          break;
        case this.constantesStatusCadena.campaniaActiva:
          this.classStyle = "bg-color-cian500";
          break;
        default:
          this.classStyle = "bg-white";
          break;
      }
    },
  },
};
