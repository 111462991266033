
export default {
  props: {
    model: { type: Boolean, required: true },
    tituloModal: { type: String, required: true },
    labelGuardar: { type: String, default: "Guardar" },
    tabIndexUltimoElemento: {
      type: String,
      default: "2",
    },
    mostrarBarraOpcciones: { type: Boolean, default: true },
    clasePersonalizada: { type: String, default: "" },
  },
  data() {
    return {
      mostrar: false,
    };
  },
  watch: {
    model(val) {
      this.mostrar = val;
    },
  },
  methods: {
    onClose() {
      this.$emit("cerrar-modal");
    },
    agregar() {
      this.$emit("agregar");
    },
    regresarUltimoElemento() {
      document
        .querySelector('[tabindex="' + this.tabIndexUltimoElemento + '"]')
        .focus();
    },
    regresarBtnGuardar() {
      document.querySelector('[tabindex="100"]').focus();
    },
    irPrimerElemento() {
      let querySelector = document.querySelector('[tabindex="2"]');
      if (!this._.isEmpty(querySelector)) querySelector.focus();
    },
  },
};
