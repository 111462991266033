
export default {
  name: "CarDetalleSeccionGlobal",
  props: {
    tituloSeccion: { String, required: true },
    mostrarAccionHeader: { Boolean, default: false },
    textoAccionHeader: { String, default: "Acción" },
    iconoAccionHeader: { String, default: "" },
  },
  methods: {
    ejecutarAccion() {
      this.$emit("ejecutar-accion");
    },
  },
};
