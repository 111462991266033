export default {
  SYNCFUSION_ESP: {
    grid: {
      EmptyRecord: "Sin registros encontrados",
      ChooseColumns: "Elegir columnas",
      Columnchooser: "Columnas",
      Search: "Buscar",
      SelectAll: "Seleccionar todo",
      CancelButton: "Cancelar",
      Equal: "Igual a",
      NotEqual: "Distinto de",
      LessThan: "Menor que",
      LessThanOrEqual: "Menor que o igual a",
      GreaterThan: "Mayor que",
      GreaterThanOrEqual: "Mayor que o igual a",
      SortAscending: "Orden ascendente",
      SortDescending: "Orden descendente",
      TextFilter: "Filtrar texto",
      DateFilter: "Filtrar fecha",
      DateTimeFilter: "Filtrar fecha",
      NumberFilter: "Filtrar cantidades",
      ClearFilter: "Limpiar filtros",
      StartsWith: "Comienza con",
      EndsWith: "Termina con",
      Contains: "Contiene",
      Between: "Entre",
      CustomFilter: "Filtro personalizado",
      ShowRowsWhere: "Mostrar registros donde",
      AND: "Y",
      OR: "O",
      MatchCase: "Coincidir mayúsculas y minúsculas",
      EnterValue: "Ingrese el valor",
      CustomFilterPlaceHolder: "Ingrese el valor",
      ChooseDate: "Elija una fecha",
      CustomFilterDatePlaceHolder: "Elija una fecha",
      SortAtoZ: "Orden ascendente (A - Z)",
      SortZtoA: "Orden descendente (Z - A)",
      SortByOldest: "Orden ascendente",
      SortByNewest: "Orden descendente",
      SortSmallestToLargest: "Orden ascendente",
      SortLargestToSmallest: "Orden descendente",
    },
    pager: {
      currentPageInfo: "Página {0} de {1}",
      totalItemInfo: " ({0} registros) ",
      totalItemsInfo: " ({0} registros) ",
      firstPageTooltip: "Primera página",
      lastPageTooltip: "Última página",
      nextPageTooltip: "Siguiente",
      previousPageTooltip: "Anterior",
    },
    datepicker: {
      placeholder: "Buscar",
      today: "Hoy",
    },
    datetimepicker: {
      placeholder: "Buscar",
      today: "Hoy",
    },
  },
};
