import axios from "axios";
import { merge } from "lodash";
const CancelToken = axios.CancelToken;
// eslint-disable-next-line no-unused-vars
let source = CancelToken.source();
export default {
  axiosCatch(error, callback) {
    if (!axios.isCancel(error) && typeof callback === "function") {
      callback(error);
    }
  },
  getAxiosCancelToken() {
    return CancelToken;
  },
  setAxiosSourceToken(token) {
    source = token;
  },
  getParams(datos = []) {
    // let session = localStorage;
    return merge({}, datos);
  },
  getPostParams(form) {
    // let session = localStorage,
    let formData = new FormData();

    for (let property in form) {
      if (property == "files") {
        form.files.forEach((element) => {
          formData.append("files[]", element);
        });
      } else if (Object.prototype.hasOwnProperty.call(property, "archivos"))
        formData.append("archivos", form[property]);
      else formData.append(property, form[property]);
    }

    return formData;
  },
  getFilePostConfig() {
    return { headers: { "Content-Type": "multipart/form-data" } };
  },
};
