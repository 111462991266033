
export default {
  name: "CardDetalleInfoGlobal",
  props: {
    tituloHeader: {
      type: String,
      default: "Datos generales",
    },
    desactivarOpciones: {
      type: Boolean,
      default: false,
    },
    mostrarOpciones: {
      type: Boolean,
      default: true,
    },
    opciones: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    validarClick(opc) {
      if (!opc.permiso) {
        return this.$emit(`${opc.accion}`, opc.accionParams);
      }
      if (this.permisos.existePermiso(opc.permiso)) {
        return this.$emit(`${opc.accion}`, opc.accionParams);
      } else {
        return;
      }
    },
    validarRuta(opc) {
      if (opc.disabled) {
        return "";
      }
      if (!opc.permiso) {
        return opc.ruta;
      }
      if (this.permisos.existePermiso(opc.permiso)) {
        return opc.ruta;
      } else {
        return "";
      }
    },
  },
};
