import { Notify } from "quasar";
// import { isEmpty } from "lodash";
import sha256 from "sha256";

export default {
  mostrarAlerta(mensaje, position = "top") {
    let conf = {
      message: mensaje,
      position,
      timeout: 2500 * 2,
      html: true,
      classes: "my-notify",
      actions: [{ icon: "close", color: "white" }],
      color: "amber-8",
    };
    Notify.create(conf);
  },

  validarHash(id, hash, next) {
    if (_.isEmpty(id.toString()) || _.isEmpty(hash.toString())) {
      let mensaje = "La URL es incorrecta para acceder empty";
      this.mostrarAlerta(mensaje, "warning");
      next({ name: "home" });
    } else {
      if (this.getHash(id) != hash.toString()) {
        let mensaje = "La URL es incorrecta para acceder";
        this.mostrarAlerta(mensaje, "warning");
        next({ name: "home" });
      }
    }
  },
  getHash(id) {
    let hashn = "";
    const n = 5;

    let sha = sha256(id.toString());
    hashn = sha.substring(sha.length - n);

    return hashn;
  },
};
