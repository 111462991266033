import { split } from "lodash";

export default {
  /*************************/
  /**PERMISOS GENERALES**/
  /***********************/
  PERMISO_TEST: "TEST",
  /**********************************/
  /*********** CONTACTOS ************/
  /**********************************/
  CONTACTOS_VISUALIZAR: "contactos.visualizar",
  CONTACTOS_CREAR: "contactos.crear",
  CONTACTOS_EDITAR: "contactos.editar",
  CONTACTOS_COMPANIA_EDITAR: "contactos.compania.editar",
  CONTACTOS_CANALCAPTACION_EDITAR: "contactos.canalCaptacion.editar",
  CONTACTOS_TELEFONO_AGREGAR: "contactos.telefono.agregar",
  CONTACTOS_TELEFONO_EDITAR: "contactos.telefono.editar",
  CONTACTOS_TELEFONO_ELIMINAR: "contactos.telefono.eliminar",
  CONTACTOS_ETIQUETAS_AGREGAR: "contactos.etiquetas.agregar",
  CONTACTOS_ETIQUETAS_ELIMINAR: "contactos.etiquetas.eliminar",
  CONTACTOS_AGENTE_EDITAR: "contactos.agente.editar",
  CONTACTOS_STATUS_EDITAR: "contactos.status.editar",
  /**********************************/
  /*********** ARTÍCULOS ************/
  /**********************************/
  ARTICULOS_VISUALIZAR: "articulos.visualizar",
  ARTICULOS_CREAR: "articulos.crear",
  ARTICULOS_STATUS_EDITAR: "articulos.status.editar",
  ARTICULOS_EDITAR: "articulos.editar",
  ARTICULOS_ELIMINAR: "articulos.eliminar",
  /**********************************/
  /*********** OPORTUNIDADES ********/
  /**********************************/
  OPORTUNIDADES_VISUALIZAR: "oportunidades.visualizar",
  OPORTUNIDADES_CREAR: "oportunidades.crear",
  OPORTUNIDADES_STATUS_EDITAR: "oportunidades.status.editar",
  OPORTUNIDADES_DESCRIPCION_EDITAR: "oportunidades.descripcion.editar",
  OPORTUNIDADES_COTIZACION_SUBIR: "oportunidades.cotizacion.subir",
  OPORTUNIDADES_COTIZACION_DESCARGAR: "oportunidades.cotizacion.descargar",
  OPORTUNIDADES_COTIZACION_ENVIAR: "oportunidades.cotizacion.enviar",
  OPORTUNIDADES_ARCHIVO_SUBIR: "oportunidades.archivo.subir",
  OPORTUNIDADES_ARCHIVO_DESCARGAR: "oportunidades.archivo.descargar",
  OPORTUNIDADES_EMAIL_VISUALIZAR: "oportunidades.email.visualizar",
  OPORTUNIDADES_SEGUIMIENTO_CREAR: "oportunidades.seguimiento.crear",
  /***************************************/
  /*********** CONVERSACIONES ************/
  /***************************************/
  CONVERSACIONES_VISUALIZAR: "conversaciones.visualizar",
  CONVERSACIONES_MENSAJEANTERIOR_VISUALIZAR:
    "conversaciones.mensajeAnterior.visualizar",
  CONVERSACIONES_CONVERSACIONANTERIOR_VISUALIZAR:
    "conversaciones.conversacionAnterior.visualizar",
  CONVERSACIONES_MENSAJE_ENVIAR: "conversaciones.mensaje.enviar",
  CONVERSACIONES_ETIQUETA_AGREGAR: "conversaciones.etiqueta.agregar",
  CONVERSACIONES_ETIQUETA_ELIMINAR: "conversaciones.etiqueta.eliminar",
  CONVERSACIONES_OPORTUNIDAD_AGREGAR: "conversaciones.oportunidad.agregar",
  /*******************************/
  /*********** EMAILS ************/
  /*******************************/
  EMAILS_VISUALIZAR: "emails.visualizar",
  EMAILS_ARCHIVO_DESCARGAR: "emails.archivo.descargar",
  /********************************/
  /*********** EMBUDOS ************/
  /********************************/
  EMBUDO_VISUALIZAR: "embudo.visualizar",
  EMBUDO_STATUS_EDITAR: "embudo.status.editar",
  /*************************************/
  /*********** SEGUIMIENTOS ************/
  /*************************************/
  SEGUIMIENTOS_VISUALIZAR: "seguimientos.visualizar",
  SEGUIMIENTOS_STATUS_EDITAR: "seguimientos.status.editar",
  SEGUIMIENTOS_EDITAR: "seguimientos.editar",
  /**************************************/
  /*********** CONFIGURACIÓN ************/
  /**************************************/
  CONFIGURACIONES_VISUALIZAR: "configuraciones.visualizar",

  existePermiso: (permiso) => {
    let temporal = localStorage.getItem("perfil") || [];
    let permisos = split(temporal, ",");
    let existe = permisos.find((p) => p == permiso);
    if (existe) return true;
    return false;
  },
};
