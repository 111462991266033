
export default {
  name: "link-global",
  props: {
    rutaNombre: {
      type: String,
      required: true,
    },
    valor: {
      type: [String, Number],
      required: true,
    },
    rutaId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    manejarClick(e) {
      if (e.ctrlKey == true) {
        this.irRutaNuevaTab();
      } else {
        this.irRuta();
      }
    },
    irRuta() {
      this.$router.push({
        name: this.rutaNombre,
        params: {
          id: this.rutaId,
          hash: this.obtenerHash(this.rutaId),
        },
      });
    },
    irRutaNuevaTab() {
      const rutaNueva = this.$router.resolve({
        name: this.rutaNombre,
        params: {
          id: this.rutaId,
          hash: this.obtenerHash(this.rutaId),
        },
      });
      window.open(rutaNueva.href, "_blank");
    },
  },
};
