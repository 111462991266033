import Vue from "vue";
import VueRouter from "vue-router";
import rutas from "./rutas";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes: rutas,
});

router.beforeEach((to, from, next) => {
  const logueado = localStorage.getItem("evelynCRMtoken");

  if (logueado === null && to.fullPath !== "/login") next("/login");
  else if (to.fullPath === "/login" && logueado !== null) next("/home");
  else if (logueado !== null && to.fullPath === "/") next("/home");
  else next();
});

export default router;
