import {
  Page,
  Filter,
  Sort,
  Reorder,
  ColumnChooser,
  Toolbar,
  Resize,
  Aggregate,
} from "@syncfusion/ej2-vue-grids";

// import { Page as PageTreeGrid, Sort as SortTreeGrid, Filter as FilterTreeGrid } from "@syncfusion/ej2-vue-treegrid";

import { CheckBoxSelection } from "@syncfusion/ej2-dropdowns";

export default {
  getMethodsGridSyncfusion() {
    return [
      Page,
      Filter,
      Sort,
      Reorder,
      ColumnChooser,
      Toolbar,
      Resize,
      Aggregate,
    ];
  },

  // getMethodsTreeGrid:() => [PageTreeGrid, SortTreeGrid,FilterTreeGrid],
  getMethodsMultiSelect: () => [CheckBoxSelection],
  getMethodsSpreadsheet: () => [],
};
