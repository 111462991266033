
export default {
  name: "menu-actualizar-status-global",
  props: {
    objeto: { type: Object, required: true },
    statusOpciones: { type: Array, required: true },
    tipoStatus: { type: Number, required: true }, // 1 Numerico, 2 Cadena
    campoStatus: { type: String, default: "status" }, // Campo de status
    campoNombreStatus: { type: String, default: "status_nombre" }, // Nombre del campo de status (si es de tipo numerico)
    edicionHabilitada: { type: Boolean, default: false },
  },
  data() {
    return {
      hoverBackground: "",
    };
  },
  computed: {},
  methods: {
    actualizarSatus(status) {
      this.$emit("actualizar-status", status);
    },
    obtenerHoverBackground(objeto, campoStatus) {
      switch (objeto[campoStatus]) {
        case this.constantesStatusCadena.lead:
          return "actualizar-status-lead-boton-bg-lead";
        case this.constantesStatusCadena.seguimientoPendiente:
          return "status-bg-gris-50";
        case this.constantesStatusCadena.cliente:
        case this.constantesStatusCadena.seguimientoCompletado:
          return "actualizar-status-lead-boton-bg-cliente";
        case this.constantesStatusCadena.descartado:
        case this.constantesStatusCadena.seguimientoCancelado:
          return "actualizar-status-lead-boton-bg-descartado";
        case 200:
          return "status-bg-activo";
        case 301:
          return "status-bg-inactivo";
        // EMBUDOS
        case this.constantesStatusCadena.embudoCian500:
          return "status-bg-cian-50";
        case this.constantesStatusCadena.embudoGris500:
          return "status-bg-gris-50";
        case this.constantesStatusCadena.embudoAzul500:
          return "status-bg-azul-50";
        case this.constantesStatusCadena.embudoAmbar500:
          return "status-bg-ambar-50";
        case this.constantesStatusCadena.embudoNaranja500:
          return "status-bg-naranja-50";
        case this.constantesStatusCadena.embudoVerde500:
          return "status-bg-verde-50";
        case this.constantesStatusCadena.embudoRojo500:
          return "status-bg-rojo-50";
        default:
          return "none";
      }
    },
  },
};
