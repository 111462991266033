
export default {
  name: "status-global",
  props: {
    status: {
      type: [Number, String],
    },
    statusNombre: {
      type: String,
    },
  },
  data() {
    return {
      color: "",
      classStyle: "",
    };
  },
  watch: {
    status() {
      this.cargarEstilos();
    },
  },
  mounted() {
    this.cargarEstilos();
  },
  methods: {
    cargarEstilos() {
      switch (this.modulo) {
        default:
          this.obtenerColorDefault();
      }
    },
    obtenerColorDefault() {
      switch (this.status) {
        case 100:
          this.classStyle = "bg-color-pendiente";
          break;
        case 200:
          this.classStyle = "bg-color-activo";
          break;
        case 300:
          this.classStyle = "bg-color-eliminado";
          break;
        case 301:
          this.classStyle = "bg-color-inactivo";
          break;
        case this.constantesStatusCadena.embudoGris500:
          this.classStyle = "bg-color-embudo-gris-500";
          break;
        case this.constantesStatusCadena.embudoCian500:
          this.classStyle = "bg-color-embudo-cian-500";
          break;
        case this.constantesStatusCadena.embudoAzul500:
          this.classStyle = "bg-color-embudo-azul-500";
          break;
        case this.constantesStatusCadena.embudoAmbar500:
          this.classStyle = "bg-color-embudo-ambar-500";
          break;
        case this.constantesStatusCadena.embudoNaranja500:
          this.classStyle = "bg-color-embudo-naranja-500";
          break;
        case this.constantesStatusCadena.embudoVerde500:
          this.classStyle = "bg-color-embudo-verde-500";
          break;
        case this.constantesStatusCadena.embudoRojo500:
          this.classStyle = "bg-color-embudo-rojo-500";
          break;
        default:
          this.classStyle = "";
          break;
      }
    },
  },
};
