
export default {
  name: "modal-busqueda-seleccion-global",
  props: {
    model: { type: Boolean, required: true },
    tituloModal: { type: String, required: true },
    datos: { type: Array, required: true },
    columnas: { type: Array, required: true },
    busqueda: { type: String, default: "" },
  },
  data() {
    return {
      mostrar: false,
      busquedaInput: "",
    };
  },
  computed: {
    registros() {
      return (
        this.datos.length +
        (this.datos.length == 1 ? " Registro" : " Registros")
      );
    },
  },
  watch: {
    model(val) {
      this.mostrar = val;
    },
    busqueda(val) {
      this.busquedaInput = val;
    },
  },
  methods: {
    onClose() {
      this.busquedaInput = "";
      this.$emit("cerrar-modal");
    },
    limpiarBusqueda() {
      this.busquedaInput = "";
      // this.$emit("buscar", this.busquedaInput);
      // this.$refs.buscar.focus();
      this.buscar();
    },
    buscar() {
      this.$emit("buscar", this.busquedaInput);
    },
    seleccionar(item) {
      this.$emit("seleccionar", item);
    },
    irPrimerElemento() {
      document.querySelector('[tabindex="2"]').focus();
    },
  },
};
