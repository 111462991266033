
export default {
  name: "HeaderDetalleGlobal",
  props: {
    folio: { type: [Number, String], default: "" },
    titulo: { type: String, default: "" },
    breadcrumbs: { type: Array, required: true },
  },
  data() {
    return {
      defaultBreadcrumbs: [{ etiqueta: "Inicio", to: "/home" }],
    };
  },
  mounted() {
    this.defaultBreadcrumbs = _.concat(
      this.defaultBreadcrumbs,
      this.breadcrumbs
    );
  },
};
