
export default {
  name: "ModalAdvertenciaGlobal",
  props: {
    modelo: { type: Boolean, required: true },
    tituloModal: { type: String, required: true },
    textoContenido: { type: String, required: true },
    mostrarDetalle: { type: Boolean, default: false },
    etiquetaBotonPrimario: { type: String, default: "Aceptar" },
  },
  data() {
    return {
      mostrar: false,
    };
  },
  watch: {
    modelo(val) {
      this.mostrar = val;
    },
  },
  methods: {
    onClose() {
      this.$emit("cerrar-modal");
    },
  },
};
