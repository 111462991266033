export default class ConstantesStatusCadena {
  //se definen constantes para las cadenas de los valores de los nuevos campos de status
  static lead = "Lead";
  static cliente = "Cliente";
  static descartado = "Descartado";

  static oportunidadEnProceso = "En Proceso";
  static oportunidadCerrada = "Cerrada";
  static oportunidadDescartada = "Descartada";

  static campaniaPendiente = "Pendiente";
  static campaniaActiva = "Activa";
  static campaniaDescartada = "Descartada";
  static campaniaFinalizada = "Finalizada";

  static seguimientoPendiente = "Pendiente";
  static seguimientoCompletado = "Completado";
  static seguimientoCancelado = "Cancelado";

  static canalCaptacionActivo = "Activo";
  static canalCaptacionInactivo = "Inactivo";

  //esta constante 'status' solo sirve para pruebas unitarias de momento
  static status = [this.lead, this.cliente, this.descartado];

  // Embudos
  static embudoGris500 = "A3ACB2";
  static embudoAzul500 = "2196F3";
  static embudoCian500 = "00BCD4";
  static embudoAmbar500 = "FFC107";
  static embudoNaranja500 = "FF9800";
  static embudoVerde500 = "4CAF50";
  static embudoRojo500 = "F44336";
}
