import Vue from "vue";
// Librerias
import { Loading, QSpinnerTail, Notify } from "quasar";
import dayjs from "dayjs";
import sha256 from "sha256";
import "dayjs/locale/es";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import _ from "lodash";
import accounting from "accounting";
import AxiosUtils from "./Services/Request/AxiosUtils";
// Constantes
import Correo from "./Constantes/Correo";
import Contrasenia from "./Constantes/Contrasenia";
import constantesStatusCadena from "./Constantes/ConstantesStatusCadena";
import Ladas from "./Constantes/Ladas";
import Permisos from "./permisos";
// Componentes
import headerGestorGlobal from "./components/headers/HeaderGestorGlobal.vue";
import tablaGestorGlobal from "./components/tablas/TablaGestorGlobal.vue";
import headerDetalleGlobal from "./components/headers/HeaderDetalleGlobal.vue";
import filtroBarraGlobal from "./components/filtros/FiltroBarraGlobal.vue";
import cardDetalleInfoGlobal from "./components/cards/CardDetalleInfoGlobal.vue";
import cardDetalleSeccionGlobal from "./components/cards/CardDetalleSeccionGlobal.vue";
import modalLateralGlobal from "./components/modales/ModalLateralGlobal.vue";
import modalCentralGlobal from "./components/modales/ModalCentralGlobal.vue";
import modalAdvertenciaGlobal from "./components/modales/ModalAdvertenciaGlobal.vue";
import modalErrorEliminarGlobal from "./components/modales/ModalErrorEliminarGlobal.vue";
import modalBusquedaSeleccionGlobal from "./components/modales/ModalBusquedaSeleccionGlobal.vue";
import LinkGlobal from "./components/globales/LinkGlobal.vue";
import LinkIrGlobal from "./components/globales/LinkIrGlobal.vue";
import StatusGlobal from "./components/globales/StatusGlobal.vue";
import StatusCadenaGlobal from "./components/globales/StatusCadenaGlobal.vue";
import iconoSelectGlobal from "./components/globales/IconoSelectGlobal.vue";
import botonBusquedaSeleccion from "./components/botones/BotonBusquedaSeleccion.vue";
import eitquetaFormularioGlobal from "./components/globales/EtiquetaFormularioGlobal.vue";
import eitquetaAyudaFormularioGlobal from "./components/globales/EtiquetaAyudaFormularioGlobal.vue";
import menuActualizarStatusGlobal from "./components/menus/MenuActualizarStatusGlobal.vue";
// Syncfusion
import metodosSyncfusion from "./metodosSyncfusion";
import traduccionesSyncFusion from "./traduccionesSyncfusion";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import { L10n } from "@syncfusion/ej2-base";
// Event Bus
import { ConversacionEventBus } from "./EventBuses/ConversacionEventBus";

// Se define internacionalizacion para dayjs
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.locale("es");

Vue.config.productionTip = false;

/*
 |--------------------------------------------------------------------------
 | Registro de componentes globales para la aplicación
 |--------------------------------------------------------------------------|
 */
Vue.component("header-gestor-global", headerGestorGlobal);
Vue.component("header-detalle-global", headerDetalleGlobal);
Vue.component("tabla-gestor-global", tablaGestorGlobal);
Vue.component("filtro-barra-global", filtroBarraGlobal);
Vue.component("card-detalle-info-global", cardDetalleInfoGlobal);
Vue.component("card-detalle-seccion-global", cardDetalleSeccionGlobal);
Vue.component("modal-lateral-global", modalLateralGlobal);
Vue.component("modal-central-global", modalCentralGlobal);
Vue.component("modal-advertencia-global", modalAdvertenciaGlobal);
Vue.component("modal-error-eliminar-global", modalErrorEliminarGlobal);
Vue.component("modal-busqueda-seleccion-global", modalBusquedaSeleccionGlobal);
Vue.component("link-global", LinkGlobal);
Vue.component("link-ir-global", LinkIrGlobal);
Vue.component("status-global", StatusGlobal);
Vue.component("status-cadena-global", StatusCadenaGlobal);
Vue.component("icono-select-global", iconoSelectGlobal);
Vue.component("boton-busqueda-seleccion", botonBusquedaSeleccion);
Vue.component("etiqueta-formulario-global", eitquetaFormularioGlobal);
Vue.component(
  "etiqueta-ayuda-formulario-global",
  eitquetaAyudaFormularioGlobal
);
Vue.component("menu-actualizar-status-global", menuActualizarStatusGlobal);

Vue.use(GridPlugin);

// Se cargan traducciones de syncfusion
L10n.load({
  "en-US": traduccionesSyncFusion.SYNCFUSION_ESP,
});

// Vue.prototype.$conversacionEventBus = ConversacionEventBus;

Object.defineProperties(Vue.prototype, {
  axiosCancelToken: { get: () => AxiosUtils.getAxiosCancelToken() },
  axiosSourceToken: {
    set: (token) => {
      AxiosUtils.setAxiosSourceToken(token);
    },
  },
  dayjs: { get: () => dayjs },
  _: { get: () => _ },
  constantesCorreo: { get: () => Correo },
  constantesLadas: { get: () => Ladas },
  constantesStatusCadena: { get: () => constantesStatusCadena },
  metodosSyncfusion: { get: () => metodosSyncfusion },
  accounting: { get: () => accounting },
  permisos: { get: () => Permisos },
  $conversacionEventBus: { get: () => ConversacionEventBus },
  sha256: { get: () => sha256 },
  loader: {
    get:
      () =>
      (show, mensaje = "", color = "blue-10") => {
        if (show) {
          document.onkeydown = () => {
            return false;
          };
          Loading.show({
            spinner: QSpinnerTail,
            message: mensaje,
            messageColor: "white",
            spinnerSize: 80,
            spinnerColor: color,
          });
        } else {
          Loading.hide();
          document.onkeydown = () => {
            return true;
          };
        }
      },
  },
  obtenerArrayParametrosQuery: {
    get:
      () =>
      (param, isArrayNumeric = false) => {
        let filtro = param
          ? typeof param === "string"
            ? param.split(",")
            : param
          : [];

        return isArrayNumeric ? filtro.map(Number) : filtro;
      },
  },
  mostrarAlerta: {
    get:
      () =>
      (mensaje, tipo = "", position = "top") => {
        let conf = {
          message: mensaje,
          position,
          timeout: 10000,
          html: true,
          classes: "my-notify",
          actions: [{ icon: "close", color: "white" }],
        };

        switch (tipo) {
          case "exito":
            conf.color = "green-8";
            break;
          case "error":
            conf.color = "red-8";
            break;
          case "warning":
            conf.color = "amber-8";
            break;
          case "info":
            conf.color = "blue-8";
            break;
          default:
            conf.color = "grey-8";
        }

        Notify.create(conf);
      },
  },
  formatearFecha: {
    get:
      () =>
      (fecha, formato = 1, showHoras = false, showSegundos = false) => {
        // dayjs().format("DD/MMMM/YYYY HH:mm:ss")
        let date = dayjs(fecha),
          horas =
            showHoras && showSegundos
              ? date.format("h:mm:ss a")
              : showHoras
              ? date.format("h:mm a")
              : "",
          mesParceado = date.format("MMM").replace(".", ""),
          mes = mesParceado.charAt(0).toUpperCase() + mesParceado.slice(1);

        // Se validan fechas que inicialmente eran null (Parseadas con Date() para dataGrid)
        if (date.isSame("1969-12-31", "year")) return "";

        switch (formato) {
          // yyyy-mm-dd -
          case 1:
            return !fecha ? "" : `${date.format("YYYY-MM-DD")} ${horas}`;
          // dd/mmm/yy
          case 2:
            return !fecha
              ? ""
              : `${date.format("DD")}/${mes}/${date.format("YY")} ${horas}`;
          // hace n minutos|horas|dias
          case 3:
            return !fecha ? "" : dayjs(fecha).fromNow();
        }
      },
  },
  fechaActual: {
    get: () => () => {
      return dayjs()
        .tz(localStorage.getItem("zonaHoraria"))
        .format("YYYY-MM-DD HH:mm:ss");
    },
  },
  formatearTelefono: {
    get:
      () =>
      (telefono, formato = 1, showLada = true, separador = " ") => {
        if (!telefono) return "";
        let lada = telefono.substring(0, telefono.length - 10);
        switch (formato) {
          // +52 555 555 5555
          case 1:
            let parte3 = telefono.substring(telefono.length - 4);
            let parte2 = telefono.substring(
              telefono.length - 7,
              telefono.length - 4
            );
            let parte1 = telefono.substring(
              telefono.length - 10,
              telefono.length - 7
            );

            let telefonoFormateado =
              parte1 + separador + parte2 + separador + parte3;
            return showLada
              ? `${lada} ${telefonoFormateado}`
              : telefonoFormateado;
        }
      },
  },
  validarDecimales: {
    get: () => (valor) => {
      if ((valor.match(/\./g) || []).length > 1) return "Cantidad inválida";
      return true;
    },
  },
  validarFecha: {
    get:
      () =>
      (
        fecha,
        tipo = 1,
        validarFechaFutura = true,
        validarAnioMinimo = true,
        validarFechaPasado = false,
        minAnio = 2019,
        minFecha = dayjs().format("YYYY-MM-DD")
      ) => {
        // Valida que no este vacío el campo
        if (!fecha) return "Fecha requerida";

        // Valida que la longitud del input sea correcta
        if (fecha.length != 10) return "Formato de fecha incorrecto";

        //// Valida que la fecha sea correcta ////
        let dia = parseInt(fecha.substring(0, 2));
        let mes = parseInt(fecha.substring(3, 5));
        let anio = parseInt(fecha.substring(6, 10));
        let esBisiesto;

        switch (mes) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12:
            if (dia < 1 || dia > 31) return "Día incorrecto, verificar";
            break;
          case 4:
          case 6:
          case 9:
          case 11:
            if (dia < 1 || dia > 30) return "Día incorrecto, verificar";
            break;
          case 2:
            if ((anio % 4 == 0 && anio % 100 != 0) || anio % 400 == 0)
              esBisiesto = true;
            else esBisiesto = false;
            if (esBisiesto) {
              if (dia < 1 || dia > 29) return "Día incorrecto, verificar";
            } else {
              if (dia < 1 || dia > 28) return "Día incorrecto, verificar";
            }
            break;
          default:
            return "Mes incorrecto, verificar";
        }
        //// Fin validación fecha correcta ////

        // Valida que la fecha no pueda ser superior a fecha actual
        if (validarFechaFutura) {
          if (
            dayjs(fecha, "DD/MM/YYYY").format("YYYY-MM-DD") >
            dayjs().format("YYYY-MM-DD")
          ) {
            return "La fecha no puede ser superior a fecha actual";
          }
        }

        // Valida que la fecha no pueda ser menor a fecha actual
        if (validarFechaPasado) {
          let f = dayjs(fecha, "DD/MM/YYYY").format("YYYY-MM-DD");
          if (dayjs(f).isBefore(dayjs(minFecha))) {
            return "La fecha no puede ser menor a " + minFecha;
          }
        }

        // Valida que el anio ingresado tenga un mínimo
        if (validarAnioMinimo) {
          if (anio <= minAnio) {
            minAnio++;
            return "El año no puede ser menor al " + minAnio;
          }
        }

        let anioActual;
        let diferencia;
        switch (tipo) {
          // Validar fecha de nacimiento
          case 2:
            anioActual = dayjs().format("YYYY");
            diferencia = anioActual - anio;
            if (diferencia < 18) return "Edad mínima 18 años";
            else if (diferencia >= 80) return "Edad máxima 80 años";
            break;
          // Caso default no valida algo mas
          default:
            break;
        }

        return true;
      },
  },
  validarContrasenia: {
    get: () => (contrasenia) => {
      if (!contrasenia) return "Contraseña requerida";
      if (Contrasenia.ESPACIO_REGEX.test(contrasenia))
        return "No se permiten espacios";
      if (contrasenia.length < 12) return "Debe contener más de 12 caracteres";
      if (!Contrasenia.MAYUSCULA_REGEX.test(contrasenia))
        return "Incluye al menos una mayúscula";
      if (!Contrasenia.MINUSCULA_REGEX.test(contrasenia))
        return "Incluye al menos una minúscula";
      if (!Contrasenia.NUMERO_REGEX.test(contrasenia))
        return "Incluye al menos un número";
      if (!Contrasenia.CARACTERES_ESPECIALES_REGEX.test(contrasenia))
        return "Incluye al menos un caracter especial";
    },
  },
  obtenerHash: {
    get: () => (id) => {
      let hashn = "";
      const n = 5;

      let sha = sha256(id.toString());
      hashn = sha.substring(sha.length - n);

      return hashn;
    },
  },
  validarCorreo: {
    get: () => (email) => {
      if (!email) return "El email es requerido";
      return email.match(Vue.prototype.constantesCorreo.CORREO_VALIDAR_REGEX)
        ? true
        : "Ingresa un email válido";
    },
  },
  obtenerIconoArchivoExtension: {
    get: () => (archivoExtencion) => {
      switch (archivoExtencion) {
        case "jpg":
        case "jpeg":
        case "png":
          return "imagen";
        case "pdf":
          return "pdf";
        case "doc":
        case "docx":
          return "word";
        case "xls":
        case "xlsx":
          return "excel";
        default:
          return "imagen";
      }
    },
  },
  esSitioWeb: {
    get: () => (cadena) => {
      const patronURL =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
      if (!patronURL.test(cadena)) {
        return "Ingresa un sitio web válido";
      }
      return true;
    },
  },
});

/*
 |--------------------------------------------------------------------------
 | Registro de directivas
 |--------------------------------------------------------------------------
 */
Vue.directive("ingresar", {
  bind(el, binding) {
    el.addEventListener("keydown", (e) => {
      //Se omiten las teclas del, espacio, tab, esc y enter
      let special = [46, 8, 9, 27, 13];
      if (binding.modifiers["decimales"]) {
        // Si existe teclado numerico agregamos sus teclas
        special.push(110, 190);
      }
      // Aceptamos combinaciones de teclas basicas sobre inputs
      if (
        special.indexOf(e.keyCode) !== -1 ||
        // Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        // Ctrl+C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        // Ctrl+X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        // home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        return;
      }
      if (
        binding.modifiers["letras"] &&
        // Solo letras
        e.keyCode >= 65 &&
        e.keyCode <= 90
      ) {
        return;
      }
      if (
        binding.modifiers["numeros"] &&
        // Solo numeros enteros
        ((!e.shiftKey && e.keyCode >= 48 && e.keyCode <= 57) ||
          // Si existe teclado numerico agregamos sus teclas
          (e.keyCode >= 96 && e.keyCode <= 105))
      ) {
        return;
      }
      e.preventDefault();
    });
  },
});

export default Vue;
