
export default {
  props: {
    model: { type: Boolean, required: true },
    tituloModal: { type: String, required: true },
    textoContenido: { type: String, required: true },
    mostrarAdvertencia: { type: Boolean, default: true },
    etiquetaBotonPrimario: { type: String, default: "Eliminar" },
    iconoModal: { type: String, default: "eliminar" },
  },
  data() {
    return {
      mostrar: false,
    };
  },
  watch: {
    model(val) {
      this.mostrar = val;
    },
  },
  methods: {
    onClose() {
      this.$emit("cerrar-modal");
    },
    eliminar() {
      this.$emit("continuar");
    },
  },
};
