export default {
  Ladas: [
    {
      name: "Anguilla",
      alpha3Code: "AIA",
      callingCodes: ["1"],
      translations: {
        es: "Anguilla",
      },
      flag: "https://flagcdn.com/ai.svg",
    },
    {
      name: "Antigua and Barbuda",
      alpha3Code: "ATG",
      callingCodes: ["1"],
      translations: {
        es: "Antigua y Barbuda",
      },
      flag: "https://flagcdn.com/ag.svg",
    },
    {
      name: "Argentina",
      alpha3Code: "ARG",
      callingCodes: ["54"],
      translations: {
        es: "Argentina",
      },
      flag: "https://flagcdn.com/ar.svg",
    },
    {
      name: "Aruba",
      alpha3Code: "ABW",
      callingCodes: ["297"],
      translations: {
        es: "Aruba",
      },
      flag: "https://flagcdn.com/aw.svg",
    },
    {
      name: "Bahamas",
      alpha3Code: "BHS",
      callingCodes: ["1"],
      translations: {
        es: "Bahamas",
      },
      flag: "https://flagcdn.com/bs.svg",
    },
    {
      name: "Barbados",
      alpha3Code: "BRB",
      callingCodes: ["1"],
      translations: {
        es: "Barbados",
      },
      flag: "https://flagcdn.com/bb.svg",
    },
    {
      name: "Belize",
      alpha3Code: "BLZ",
      callingCodes: ["501"],
      translations: {
        es: "Belice",
      },
      flag: "https://flagcdn.com/bz.svg",
    },
    {
      name: "Bermuda",
      alpha3Code: "BMU",
      callingCodes: ["1"],
      translations: {
        es: "Bermudas",
      },
      flag: "https://flagcdn.com/bm.svg",
    },
    {
      name: "Bolivia (Plurinational State of)",
      alpha3Code: "BOL",
      callingCodes: ["591"],
      translations: {
        es: "Bolivia",
      },
      flag: "https://flagcdn.com/bo.svg",
    },
    {
      name: "Brazil",
      alpha3Code: "BRA",
      callingCodes: ["55"],
      translations: {
        es: "Brasil",
      },
      flag: "https://flagcdn.com/br.svg",
    },
    {
      name: "Canada",
      alpha3Code: "CAN",
      callingCodes: ["1"],
      translations: {
        es: "Canadá",
      },
      flag: "https://flagcdn.com/ca.svg",
    },
    {
      name: "Cayman Islands",
      alpha3Code: "CYM",
      callingCodes: ["1"],
      translations: {
        es: "Islas Caimán",
      },
      flag: "https://flagcdn.com/ky.svg",
    },
    {
      name: "Chile",
      alpha3Code: "CHL",
      callingCodes: ["56"],
      translations: {
        es: "Chile",
      },
      flag: "https://flagcdn.com/cl.svg",
    },
    {
      name: "Colombia",
      alpha3Code: "COL",
      callingCodes: ["57"],
      translations: {
        es: "Colombia",
      },
      flag: "https://flagcdn.com/co.svg",
    },
    {
      name: "Costa Rica",
      alpha3Code: "CRI",
      callingCodes: ["506"],
      translations: {
        es: "Costa Rica",
      },
      flag: "https://flagcdn.com/cr.svg",
    },
    {
      name: "Cuba",
      alpha3Code: "CUB",
      callingCodes: ["53"],
      translations: {
        es: "Cuba",
      },
      flag: "https://flagcdn.com/cu.svg",
    },
    {
      name: "Curaçao",
      alpha3Code: "CUW",
      callingCodes: ["599"],
      translations: {
        es: "Curaçao",
      },
      flag: "https://flagcdn.com/cw.svg",
    },
    {
      name: "Dominica",
      alpha3Code: "DMA",
      callingCodes: ["1"],
      translations: {
        es: "Dominica",
      },
      flag: "https://flagcdn.com/dm.svg",
    },
    {
      name: "Dominican Republic",
      alpha3Code: "DOM",
      callingCodes: ["1"],
      translations: {
        es: "República Dominicana",
      },
      flag: "https://flagcdn.com/do.svg",
    },
    {
      name: "Ecuador",
      alpha3Code: "ECU",
      callingCodes: ["593"],
      translations: {
        es: "Ecuador",
      },
      flag: "https://flagcdn.com/ec.svg",
    },
    {
      name: "El Salvador",
      alpha3Code: "SLV",
      callingCodes: ["503"],
      translations: {
        es: "El Salvador",
      },
      flag: "https://flagcdn.com/sv.svg",
    },
    {
      name: "United States of America",
      alpha3Code: "USA",
      callingCodes: ["1"],
      translations: {
        es: "Estados Unidos",
      },
      flag: "https://flagcdn.com/us.svg",
    },
    {
      name: "Falkland Islands (Malvinas)",
      alpha3Code: "FLK",
      callingCodes: ["500"],
      translations: {
        es: "Islas Malvinas",
      },
      flag: "https://flagcdn.com/fk.svg",
    },
    {
      name: "French Guiana",
      alpha3Code: "GUF",
      callingCodes: ["594"],
      translations: {
        es: "Guayana Francesa",
      },
      flag: "https://flagcdn.com/gf.svg",
    },
    {
      name: "Greenland",
      alpha3Code: "GRL",
      callingCodes: ["299"],
      translations: {
        es: "Groenlandia",
      },
      flag: "https://flagcdn.com/gl.svg",
    },
    {
      name: "Grenada",
      alpha3Code: "GRD",
      callingCodes: ["1"],
      translations: {
        es: "Grenada",
      },
      flag: "https://flagcdn.com/gd.svg",
    },
    {
      name: "Guadeloupe",
      alpha3Code: "GLP",
      callingCodes: ["590"],
      translations: {
        es: "Guadalupe",
      },
      flag: "https://flagcdn.com/gp.svg",
    },
    {
      name: "Guatemala",
      alpha3Code: "GTM",
      callingCodes: ["502"],
      translations: {
        es: "Guatemala",
      },
      flag: "https://flagcdn.com/gt.svg",
    },
    {
      name: "Guyana",
      alpha3Code: "GUY",
      callingCodes: ["592"],
      translations: {
        es: "Guyana",
      },
      flag: "https://flagcdn.com/gy.svg",
    },
    {
      name: "Haiti",
      alpha3Code: "HTI",
      callingCodes: ["509"],
      translations: {
        es: "Haiti",
      },
      flag: "https://flagcdn.com/ht.svg",
    },
    {
      name: "Honduras",
      alpha3Code: "HND",
      callingCodes: ["504"],
      translations: {
        es: "Honduras",
      },
      flag: "https://flagcdn.com/hn.svg",
    },
    {
      name: "Jamaica",
      alpha3Code: "JAM",
      callingCodes: ["1"],
      translations: {
        es: "Jamaica",
      },
      flag: "https://flagcdn.com/jm.svg",
    },
    {
      name: "Martinique",
      alpha3Code: "MTQ",
      callingCodes: ["596"],
      translations: {
        es: "Martinica",
      },
      flag: "https://flagcdn.com/mq.svg",
    },
    {
      name: "Mexico",
      alpha3Code: "MEX",
      callingCodes: ["52"],
      translations: {
        es: "México",
      },
      flag: "https://flagcdn.com/mx.svg",
    },
    {
      name: "Montserrat",
      alpha3Code: "MSR",
      callingCodes: ["1"],
      translations: {
        es: "Montserrat",
      },
      flag: "https://flagcdn.com/ms.svg",
    },
    {
      name: "Nicaragua",
      alpha3Code: "NIC",
      callingCodes: ["505"],
      translations: {
        es: "Nicaragua",
      },
      flag: "https://flagcdn.com/ni.svg",
    },
    {
      name: "Panama",
      alpha3Code: "PAN",
      callingCodes: ["507"],
      translations: {
        es: "Panamá",
      },
      flag: "https://flagcdn.com/pa.svg",
    },
    {
      name: "Paraguay",
      alpha3Code: "PRY",
      callingCodes: ["595"],
      translations: {
        es: "Paraguay",
      },
      flag: "https://flagcdn.com/py.svg",
    },
    {
      name: "Peru",
      alpha3Code: "PER",
      callingCodes: ["51"],
      translations: {
        es: "Perú",
      },
      flag: "https://flagcdn.com/pe.svg",
    },
    {
      name: "Puerto Rico",
      alpha3Code: "PRI",
      callingCodes: ["1"],
      translations: {
        es: "Puerto Rico",
      },
      flag: "https://flagcdn.com/pr.svg",
    },
    {
      name: "Saint Barthélemy",
      alpha3Code: "BLM",
      callingCodes: ["590"],
      translations: {
        es: "San Bartolomé",
      },
      flag: "https://flagcdn.com/bl.svg",
    },
    {
      name: "Saint Kitts and Nevis",
      alpha3Code: "KNA",
      callingCodes: ["1"],
      translations: {
        es: "San Cristóbal y Nieves",
      },
      flag: "https://flagcdn.com/kn.svg",
    },
    {
      name: "Saint Lucia",
      alpha3Code: "LCA",
      callingCodes: ["1"],
      translations: {
        es: "Santa Lucía",
      },
      flag: "https://flagcdn.com/lc.svg",
    },
    {
      name: "Saint Martin (French part)",
      alpha3Code: "MAF",
      callingCodes: ["590"],
      translations: {
        es: "Saint Martin",
      },
      flag: "https://flagcdn.com/mf.svg",
    },
    {
      name: "Suriname",
      alpha3Code: "SUR",
      callingCodes: ["597"],
      translations: {
        es: "Surinam",
      },
      flag: "https://flagcdn.com/sr.svg",
    },
    {
      name: "Trinidad and Tobago",
      alpha3Code: "TTO",
      callingCodes: ["1"],
      translations: {
        es: "Trinidad y Tobago",
      },
      flag: "https://flagcdn.com/tt.svg",
    },
    {
      name: "Turks and Caicos Islands",
      alpha3Code: "TCA",
      callingCodes: ["1"],
      translations: {
        es: "Islas Turks y Caicos",
      },
      flag: "https://flagcdn.com/tc.svg",
    },
    {
      name: "Uruguay",
      alpha3Code: "URY",
      callingCodes: ["598"],
      translations: {
        es: "Uruguay",
      },
      flag: "https://flagcdn.com/uy.svg",
    },
    {
      name: "Venezuela (Bolivarian Republic of)",
      alpha3Code: "VEN",
      callingCodes: ["58"],
      translations: {
        es: "Venezuela",
      },
      flag: "https://flagcdn.com/ve.svg",
    },
  ],
};
