import Vue from "vue";

import "quasar/dist/quasar.css";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/line-awesome/line-awesome.css";
import "@quasar/extras/mdi-v4/mdi-v4.css";
import { Quasar, Notify } from "quasar";
import lang from "quasar/lang/es.js";

Vue.use(Quasar, {
  config: {
    brand: {
      secondary: "#97A3A3",
      accent: "#45046a",
      dark: "#212527",
      positive: "#21BA45",
      negative: "#C10015",
      info: "#31CCEC",
      warning: "#F2C037",
    },
  },
  components: {
    /* not needed if importStrategy is not 'manual' */
  },
  directives: {
    /* not needed if importStrategy is not 'manual' */
  },
  plugins: { Notify },
  lang: lang,
});
