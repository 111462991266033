import permisos from "../permisos";
import { Notify } from "quasar";

export default {
  mostrarAlert(mensaje, tipo = "", position = "top") {
    let conf = {
      message: mensaje,
      position,
      timeout: 2500 * 2,
      html: true,
      classes: "my-notify",
      actions: [{ icon: "close", color: "white" }],
    };

    switch (tipo) {
      case "exito":
        conf.color = "green-8";
        break;
      case "error":
        conf.color = "red-8";
        break;
      case "warning":
        conf.color = "amber-8";
        break;
      default:
        conf.color = "grey-2";
    }

    Notify.create(conf);
  },
  validarAccesoRuta(permiso, next) {
    if (!permisos.existePermiso(permiso)) {
      let mensaje =
        "No cuenta con los permisos necesarios para realizar esta acción (" +
        permiso +
        ")";
      this.mostrarAlert(mensaje, "warning");
      next({ name: "home" });
    } else {
      next();
    }
  },
};
